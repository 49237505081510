.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.license {
  display: inline-block;
  border: 1px solid;
  margin: 5px;
  padding: 5px;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  box-sizing: content-box;
  border-width: 2px;
  margin: -2px;
  border-style: solid;
  border-image: linear-gradient(to right bottom, #FEDB37, #8A6E2F);
  border-image-slice: 1;
  color: #000000;
}

.license:hover {
  cursor: pointer;
}

.crop-container {
  position: relative;
  width: 100%;
  height: 500px;
  background-color: #282c34;
}
.icon {
  -webkit-transition: fill .4s ease;
  -moz-transition: fill .4s ease;
  -o-transition: fill .4s ease;
  transition: fill .4s ease;
  fill: #FEDB37;
  
}
.icon:hover {
  fill: #8A6E2F;

}

.edit .icon {
  width: 40px;
}
.st0{
  fill-rule:evenodd;
  clip-rule:evenodd;
}

.btn {
    margin: 5px 0px 0px 0px;
    background-color: #3f51b5;
    border-color: #3f51b5;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.btn:hover .btn:active {
  background-color: #3f51b5 !important;
  border-color: #3f51b5 !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

body {
  background: #eeeeee;
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
}

.cut-text { 
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.5em; 
  white-space: nowrap;
  margin: 0px !important;
}

.stats {
  width: 100%;
  margin-bottom: 30px;
  background-color: #EEEEEE;
  border-collapse: collapse;
  border: 1px solid;
  -webkit-box-shadow: 3px 3px 5px 0px #666;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 0px #666;  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 0px #666;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
.stats td {
  border-collapse: collapse;
  border: 1px solid;
  padding: 5px;
}
.stats th {
  background-color: #343a40;
  color: #FFFFFF;
  border-collapse: collapse;
  border: 1px solid #000000;
  padding: 5px;
  text-align: center;
}
.stats td.num {
  text-align: right;
}
.card-stats-th {
  padding-top: 10px !important;
}
.button-width {
  width: 180px;
}

.license-image {
  width: 100%;
  max-width: 200px;
}

.license-image-background {
  color: #FFFFFF;
  background-color: #303030;
  padding-top: 12px !important;
  width: 150px !important;
  margin: 1px;
  text-align: center;
}
.license-title {
  text-align: center;
}
.licenses-wrapper {
  text-align: center;
}
.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.align-center {
  text-align: center;
}
.margin-center {
  margin: 0px auto;
}
.hidden {
  display: none;
}
.padding-bottom-5 {
  padding: 5px;
}
.object-image {
  display: block;
  width: 50%;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.image-upload {
  background-color: #303030;
}
.text-center {
  text-align: center;
}
.chart-wrapper {
  padding: 2%;
  display: inline-block;
}

.line-chart {
  width: 96%;
  height: 400px;
}

.sub.chart-wrapper {
  width: 33%;
  height: 300px;
}
.cursor-pointer {
  cursor: pointer !important;
}
.img-tab-wrapper > div {
  padding: 10px 0px 10px 0px !important;
}
.license-view{
  width: 50%;
  height: 500px;
}
.background-white {
  background-color: #FFFFFF;
}
.MuiInputBase-root > input {
  margin-top: 10px;
}
.error {
  padding: 15px;
  background-color: #FF3333;
  color: #FFFFFF;
}
.btn-card {
  margin-top: 25px !important;
}
.disabled {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.disabled:hover {
  -webkit-filter: none;
  filter: none;
}

@media (max-width: 700px) {
  .sub.chart-wrapper {
    width: 96%;
  }
}
@media only screen and (max-width: 640px) {
  .license-image-background {
    width: 49% !important;
  }
  .object-image {
    width: 100%;
  }
  .license-view{
    width: 100%;
  }  
}
.license-link {
  max-width: 100px;
  margin: 10px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
}
img.language {
  width: 50px;
}
img.transparent {
  opacity: 0.5;
}